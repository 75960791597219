const defaultsData = {
    ChannelOpen: {
        TermosDeUso: {
            width: "100",
            name: "EU LI E CONCORDO COM OS TERMOS DE USO",
            type: "checkbox",
            required: true,
            placeholder: ""
        },
        realizarRelato: {
            type: "title",
            name: "Realizar relato"
        },
        texto1: {
            type: "texto",
            name: "Você pode escolher fazer um relato ou pode identificar-se. A opção identificada é voltada para os casos em que o relator se disponibiliza a ser contatado para esclarecimento de possíveis dúvidas sobre o relato fornecido. Relatos com identificação são importantes, pois podem fazer com que a apuração seja mais efetiva. Lembrando que este é um canal seguro e confiável."
        },
        email: {
            width: "100",
            name: "Coloque o E-mail caso queira se identificar",
            type: "input",
            required: false,
            placeholder: "",
            description: "Agradecemos sua iniciativa e confiança."
        },
        dados: {
            type: "title",
            name: "Dados do incidente"
        },
        Relato: {
            width: "100",
            name: "Tipo do relato",
            type: "select",
            required: true,
            placeholder: "Tipo do relato",
            options: [
                "Agressão Física",
                "Assédio Moral",
                "Assédio Sexual",
                "Descumprimento de protocolos – COVID-19",
                "Desvio de comportamento",
                "Discriminação",
                "Descumprimento de normas e políticas internas",
                "Destruição ou danos aos patrimônios da empresa",
                "Favorecimento ou conflito de interesses",
                "Fraude",
                "Internet e eventos externos",
                "Pagamento ou recebimento impróprio (corrupção)",
                "Relacionamento íntimo com subordinação direta",
                "Roubo, furto ou desvio de materiais",
                "Uso ou tráfico de substâncias proibidas",
                "Vazamento ou uso indevido de informações",
                "Violação de leis ambientais",
                "Violação de leis trabalhistas",
                "Violação de leis não explícitas nas demais categorias"
            ]
        },
        Localidade: {
            width: "100",
            name: "Localidade",
            type: "input",
            required: true,
            placeholder: ""
        },
        DataeHora: {
            width: "100",
            name: "Data e horário aproximado da ocorrência",
            type: "input",
            required: true,
            placeholder: ""
        },
        Testemunha: {
            width: "100",
            name: "Há testemunha? Se sim, favor informar o nome da testemunha",
            type: "input",
            required: true,
            placeholder: ""
        },
        descricao: {
            type: "title",
            name: "Para que possamos averiguar melhor a ocorrência do evento, favor fornecer as seguintes informações na descrição do relato: nome dos envolvidos, onde o evento ocorreu, frequência com a qual o evento ocorre ou poderá ocorrer. Além disso, caso possua, favor encaminhar no campo “anexar arquivo” evidências de que o evento ocorreu, como por exemplo: vídeos, prints de tela, troca de mensagens/e-mails e gravações."
        },
        Anexo: {
            width: "100",
            name: "Anexar arquivo",
            type: "file",
            required: false,
            placeholder: "" 
        },
        EventoOcorrido: {
            width: "100",
            name: "Descreva o evento ocorrido em detalhes",
            type: "textarea",
            required: true,
            placeholder: ""
        },
        Aceitar: {
            width: "100",
            name: "Declaro estar de acordo com as informações declaradas acima",
            type: "checkbox",
            required: true,
            placeholder: ""
        },
    },

    Contato: {
        name: {
            width: "100",
            name: "Seu nome",
            type: "input",
            required: true,
            placeholder: "",
        },
        email: {
            width: "100",
            name: "Seu email (OBRIGATÓRIO)",
            type: "input",
            required: true,
            placeholder: ""
        },
        Telefone: {
            width: "50",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            mask: "phone"
        },
        Assunto: {
            width: "50",
            name: "Assunto",
            type: "input",
            required: true,
            placeholder: "" 
        },
        Mensagem: {
            width: "100",
            name: "A sua mensagem",
            type: "textarea",
            required: true,
            placeholder: ""
        },
        Aceitar: {
            width: "10",
            name: "Eu aceito a utilização dos meus dados",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'checkbox'
        },
    },

    Newsletter: {
        email: {
            width: "100",
            name: "",
            type: "input",
            required: true,
            placeholder: "E-mail"
        },
    },

    TrabalheConosco: {
        name: {
            width: "100",
            name: "Seu nome",
            type: "input",
            required: true,
            placeholder: "",
        },
        email: {
            width: "100",
            name: "Seu email (OBRIGATÓRIO)",
            type: "input",
            required: true,
            placeholder: ""
        },
        Telefone: {
            width: "50",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            mask: "phone"
        },
        Assunto: {
            width: "50",
            name: "Assunto",
            type: "input",
            required: true,
            placeholder: "" 
        },
        Mensagem: {
            width: "100",
            name: "A sua mensagem",
            type: "textarea",
            required: true,
            placeholder: ""
        },
        Aceitar: {
            width: "10",
            name: "Eu aceito a utilização dos meus dados",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'checkbox'
        },
    },

    SVT: {
        name: {
            width: "100",
            name: "Seu nome",
            type: "input",
            required: true,
            placeholder: "",
        },
        email: {
            width: "100",
            name: "Seu email (OBRIGATÓRIO)",
            type: "input",
            required: true,
            placeholder: ""
        },
        Telefone: {
            width: "50",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            mask: "phone"
        },
        Empresa: {
            width: "50",
            name: "Nome da Empresa",
            type: "input",
            required: true,
            placeholder: "" 
        },
        CNPJ: {
            width: "50",
            name: "CPF ou CNPJ",
            type: "input",
            required: true,
            placeholder: ""
        },
        Mensagem: {
            width: "100",
            name: "Explique o motivo da sua visita",
            type: "textarea",
            required: true,
            placeholder: ""
        },
        Aceitar: {
            width: "10",
            name: "Eu aceito a utilização dos meus dados",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'checkbox'
        },
    },

    SPT: {
        name: {
            width: "100",
            name: "Seu nome",
            type: "input",
            required: true,
            placeholder: "",
        },

        email: {
            width: "100",
            name: "Seu email (OBRIGATÓRIO)",
            type: "input",
            required: true,
            placeholder: ""
        },

        Telefone: {
            width: "50",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            mask: "phone"
        },

        CNPJ: {
            width: "50",
            name: "CPF ou CNPJ",
            type: "input",
            required: true,
            placeholder: ""
        },

        Empresa: {
            width: "50",
            name: "Nome da Empresa",
            type: "input",
            required: true,
            placeholder: "" 
        },

        TipoPalestraTreinamento: {
            width: "50",
            name: "",
            type: "select",
            required: true,
            placeholder: 'Tipo de treinamento ou palestra',
            options: [
                'Paletra técnica',
                'Treinamento Técnico',
                'Palestra comercial',
                'Treinamento comercial'
            ]
            
        },

        PublicoAlvo: {
            width: "50",
            name: "",
            type: "select",
            required: true,
            placeholder: 'Qual o público alvo?',
            options: [
                'Televendas',
                'Aplicador',
                'Balconista',
                'Mecânico'
            ]
            
        },

        Data: {
            width: "50",
            name: "Sugestão de data e hora",
            type: "input",
            required: true,
            placeholder: 'mm/dd/yyyy',
            typeInput: 'date'
        },

        Horario: {
            width: "50",
            name: "",
            type: "select",
            required: true,
            placeholder: 'Hora',
            options: [
                '8:00',
                '9:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00'
            ]
            
        },

        Local: {
            width: "50",
            name: "",
            type: "select",
            required: true,
            placeholder: "Possui local adequado para a palestra ou treinamento?",
            options: [
                "Sim",
                "Não"
            ]
        },

        Mensagem: {
            width: "100",
            name: "",
            type: "textarea",
            required: true,
            placeholder: "Qual a necessidade da palestra ou treinamento?"
        },

        Aceitar: {
            width: "10",
            name: "Eu aceito a utilização dos meus dados",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'checkbox'
        },
    },

    DT: {
        name: {
            width: "100",
            name: "Seu nome",
            type: "input",
            required: true,
            placeholder: "",
        },
        email: {
            width: "100",
            name: "Seu email (OBRIGATÓRIO)",
            type: "input",
            required: true,
            placeholder: ""
        },
        Telefone: {
            width: "50",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            mask: "phone"
        },
        Empresa: {
            width: "50",
            name: "Nome da empresa",
            type: "input",
            required: true,
            placeholder: ""
        },
        CNPJ: {
            width: "50",
            name: "CPF ou CNPJ",
            type: "input",
            required: true,
            placeholder: ""
        },
        Mensagem: {
            width: "100",
            name: "",
            type: "textarea",
            required: true,
            placeholder: "Qual sua dúvida Técnica?"
        },
        Aceitar: {
            width: "10",
            name: "Eu aceito a utilização dos meus dados",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'checkbox'
        },
    },

    FGW: {
        motivo: {
            name: "Motivo da garantia",
            type: "title"
        },
        motivoGarantia: {
            width: "10",
            name: "Eu aceito a utilização dos meus dados",
            type: "radio",
            required: true,
            placeholder: "",
            typeInput: 'checkbox',
            placeholder: 'Hora',
            options: [
                'Devolução',
                'Substituição de peças',
                'Sinistro'
            ]
        },
        Dados: {
            name: "Dados do cliente direto Wega",
            type: "title"
        },
        
        nome: {
            width: "10",
            name: "Nome",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        email: {
            width: "10",
            name: "E-mail",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        telefone: {
            width: "10",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        contato: {
            width: "10",
            name: "Contato",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        bairro: {
            width: "10",
            name: "Bairro",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        cidade: {
            width: "10",
            name: "Cidade",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },
        estado: {
            width: "10",
            name: "Estado",
            type: "select",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
            options: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MS',
                'MT',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ]
        },


        TrocaDeFiltro: {
            name: "Dados de onde foi efetuada a TROCA do Filtro ou Vela Wega",
            type: "title"
        },

        TrocaDeFiltroTitulo: {
            name: "Exemplo: Local (Troca de óleo, Posto de Gasolina, Oficina Mecânica, Autopeças e Outros).",
            type: "title",
            // required: true,
            // placeholder: "",
            // typeInput: "",
            // placeholder: ""
        },

        TrocaDeFiltroNome: {
            width: "10",
            name: "Nome",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },
        TrocaDeFiltroEmail: {
            width: "10",
            name: "E-mail",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },
        TrocaDeFiltroTelefone: {
            width: "10",
            name: "Telefone",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },
        TrocaDeFiltroContato: {
            width: "10",
            name: "Contato",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        TrocaDeFiltroEndereco: {
            width: "10",
            name: "Endereço",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        TrocaDeFiltroLocal: {
            width: "10",
            name: "Local",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: ''
        },

        TrocaDeFiltroBairro: {
            width: "10",
            name: "Bairro",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        TrocaDeFiltroCidade: {
            width: "10",
            name: "Cidade",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        TrocaDeFiltroEstado: {
            width: "10",
            name: "Estado",
            type: "select",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
            options: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MS',
                'MT',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ]
        },

        InfoFiltroVela: {
            name: "Informações do filtro ou vela",
            type: "title"
        },

        

        // InfoFiltroCodigo: {
        //     width: "10",
        //     name: "Código",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },

        // InfoFiltroNota: {
        //     width: "10",
        //     name: "Nota Fiscal",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },

        // InfoFiltroQuantidade: {
        //     width: "10",
        //     name: "Quantidade",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },

        // InfoFiltroLote: {
        //     width: "10",
        //     name: "Lote",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },

        // InfoFiltroTipoDefeito: {
        //     width: "10",
        //     name: "Tipo de defeito",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },

        InfoFiltroButton: {
            name: 'Adicionar',
            type: 'button'
        },

        infoFiltroTabela: {
            name: "Tabela",
            type: "table",
            codigo:'Codigo Wega',
            notaFiscal: 'Nota Fiscal',	
            quantidade: 'Quantidade',	
            lote: 'Lote',	
            tipo: 'Tipo de defeito',	
            button: 'Remover'
        },

        InfoVeiculo: {
            name: "Informações do veículo",
            type: "title"
        },

        InfoVeiculoMarca: {
            width: "10",
            name: "Marca",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        InfoVeiculoModelo: {
            width: "10",
            name: "Modelo",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        InfoVeiculoAno: {
            width: "10",
            name: "Ano",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        InfoVeiculoMotorizacao: {
            width: "10",
            name: "Motorização",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        InfoVeiculoTrocaQuilometragem: {
            width: "10",
            name: "Quilometragem no momento da troca (Km)",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        InfoVeiculoAtualQuilometragem: {
            width: "10",
            name: "Quilometragem atual (Km)",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        infoVeiculoRodagem: {
            name: "Ambiente de rodagem",
            type: "title"
        },

        infoVeiculoRodagemAmbiente: {
            width: "10",
            name: "Ambiente de rodagem",
            type: "radio",
            required: true,
            placeholder: "",
            typeInput: 'checkbox',
            placeholder: 'Hora',
            options: [
                'Urbano',
                'Rural',
                'Misto',
            ]
        },

        infoVeiculoResumoOcorrido: {
            width: "100",
            name: "Resumo do ocorrido",
            type: "textarea",
            required: true,
            placeholder: ""
        },

        ReivindicacaoCliente: {
            name: "Reivindicação do cliente (Preencher somente em caso de sinistro)",
            type: "title"
        },

        // ReivindicacaoDeDespesas: {
        //     width: "10",
        //     name: "Despesas:",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },

        // ReivindicacaoValorTotal: {
        //     width: "10",
        //     name: "Quilometragem atual (Km):",
        //     type: "input",
        //     required: true,
        //     placeholder: "",
        //     typeInput: 'text',
        //     placeholder: '',
        // },
        
        ReivindicacaoClienteButton: {       
            name: "Adicionar",
            type: "button"
        },

        ReivindicacaoTabela: {        
            name: "Tabela",
            type: "table-revindicacao",
            despesas:'Despesas',
            total: 'Valor Total',
            button: 'Remover'
        },

        ProcessoGarantia: {
            name: "Declaro estar ciente de todos os tramites do processo de garantia da WEGA FILTROS e que são verdadeiras todas as informações acima relacionadas, sob pena de nulidade do processo de garantia",
            type: "title"
        },

        ProcessoGarantiaRazaoSocial: {
            width: "10",
            name: "Nome/Razão Social",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'text',
            placeholder: '',
        },

        ProcessoGarantiaCNPJ: {
            width: "50",
            name: "CPF/CNPJ",
            type: "input",
            required: true,
            placeholder: ""
        },

        ProcessoGaratiaAceitar: {
            width: "10",
            name: "Declaro estar de acordo com as informações declaradas acima",
            type: "input",
            required: true,
            placeholder: "",
            typeInput: 'checkbox'
        },
    }    

};

export default defaultsData;
// export { defaults }